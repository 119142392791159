<template>
    <AdapterFrame>
        <div class="tjBox">
            <div style="padding-top: 10px;text-align: center">
                <p>{{ Battery }}</p>
            </div>
            <div class="fontBox">
                <div class="timeBox" style="padding-left: 0px">
                    <span>{{currenTime}}</span>
                </div>
                <div style="padding-top: 10px;text-align: center">
                    <p style="text-align: left">{{ Report }}</p>
<!--                    <p>&#45;&#45;&#45;&#45;&#45;&#45;{{ Battery }}&#45;&#45;&#45;&#45;&#45;&#45;</p>-->
                </div>
                <hr class="separator">
                <div style="padding-top: 10px;text-align: center">
                    <p id="testResultId">{{ testResult }}</p>
                </div>
            </div>
            <div class="contentBox">
                <div class="cardItemBox">
                    <div class="cardItem">
                        <custom-progress-bar :progressValue="socProgress" />
                        <div class="cardTit">
                            <div>{{ socTitle }}</div>
                            <div>{{ socValue }}</div>
                        </div>
                        <div class="cardTit" style="line-height: 2;">
                            <div>{{ volTitle }}</div>
                            <div>{{ volValue }}</div>
                        </div>
                        <custom-progress-bar :progressValue="sohProgress" />
                        <div class="cardTit">
                            <div>{{ sohTitle }}</div>
                            <div>{{ sohValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ ccaTitle }}</div>
                            <div>{{ ccaValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ rTitle }}</div>
                            <div>{{ rValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ stdTitle }}</div>
                            <div>{{ stdValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ typeTitle }}</div>
                            <div>{{ typeValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ standardTitle }}</div>
                            <div>{{ standardValue }}</div>
                        </div>
                        <div class="cardTit">
                            <div>{{ languageTitle }}</div>
                            <div>{{ languageValue }}</div>
                        </div>
                    </div>
                    <hr class="separator">
                </div>
            </div>
        </div>
    </AdapterFrame>
</template>

<script>
    import CustomProgressBar from '../components/CustomProgressBar.vue';
    import AdapterFrame from '@/components/AdapterFrame.vue';
    import axios from "axios";
    import Vue from 'vue';
    import { Divider } from 'vant-green';
    Vue.use(Divider);
    export default {
        name: 'Login',
        components: {
            AdapterFrame,
            CustomProgressBar
        },
        data() {
            return {
                currenTime: '',
                tjdctype: {},
                tjstand: {},
                tjstd: {},
                tjyuyan: {},

                Report: 'Test Report',
                Battery: 'Battery Test',
                testResult: '',
                testResultColor: '',
                socProgress: 0,
                socTitle: 'SOC',
                volTitle: 'VOL',
                sohTitle: 'SOH',
                ccaTitle: 'CCA',
                rTitle: 'R',
                stdTitle: 'STD',
                typeTitle: 'Type',
                standardTitle: 'Standard',
                languageTitle: 'Language',
                socValue: '0%',
                volValue: '0V',
                sohProgress: 0,
                sohValue: '0%',
                ccaValue: '55cca',
                rValue: '53.18',
                stdValue: '100A',
                typeValue: 'Regular Flooded',
                standardValue: 'CCA',
                languageValue: 'English',
            };
        },
        created(){
            var currentTime = new Date();
            var currentYear = currentTime.getFullYear();
            var currentMonth = currentTime.getMonth() + 1; // 月份从0开始，需要加1
            var currentDay = currentTime.getDate();
            var currentHour = currentTime.getHours();
            var currentMinute = currentTime.getMinutes();
            if (currentMinute < 10){
                currentMinute = '0' + currentMinute
            }
            this.currenTime = currentYear + "-" + currentMonth + "-" + currentDay + " " + currentHour + " : " + currentMinute
            this.readDict()
        },
        methods: {
            initData(){
                var param = this.$route.query.t
                // /1269/0072/0100/04039/010/015/03/01/01/00/01/b0
                let split = param.split("/");
                var vol = split[1] //12.69V
                var cca = split[2] //72CCA
                var std = split[3] //100CCA
                var r = split[4] //40.39 m&
                var soc = split[5] //10%
                var soh = split[6] //15%
                var testResult = split[7] //测试结果
                var ctype = split[8] //Regular Flooded
                var stand = split[9] // stand
                var yuyan = split[10] //多语言
                var fxy = split[11] //电池分板仪

                console.log(split);
                // this.testResult = this.tjresult[testResult];
                this.socValue = Number(soc)+"%";
                this.volValue = Number(vol)/100+"V";
                this.socProgress = Number(soc)
                this.sohValue = Number(soh)+"%";
                // this.sohProgress = Number(soh)
                this.sohProgress = Number(soh)
                // this.ccaValue = cca //要计算
                this.rValue = Number(r)/100+"mΩ";
                // this.stdValue =  stand;
                this.typeValue = this.tjdctype[ctype];
                this.standardValue = this.tjstand[stand];
                var yuyanObj = this.tjyuyan[yuyan]
                this.languageValue = yuyanObj["name"];
                this.languageTitle = yuyanObj["Language"];
                this.Report = yuyanObj["Report"];
                this.Battery = yuyanObj["Battery"];
                this.standardTitle = yuyanObj["Standard"];
                this.typeTitle = yuyanObj["Type"];

                var tjresult = yuyanObj["testResult"];

                if(testResult == '00'){
                    this.testResultColor = '#54DB1E';
                }
                if(testResult == '01'){
                    this.testResultColor = '#D9DB12';
                }
                if(testResult == '02'){
                    this.testResultColor = '#DB1B1A';
                }
                if(testResult == '03'){
                    this.testResultColor = '#DB1B1A';
                }
                if(testResult == '04'){
                    this.testResultColor = '#D9DB12';
                }
                document.getElementById("testResultId").style.color = this.testResultColor;
                this.testResult = tjresult[testResult];

                if(Number(stand)==0){
                    this.ccaValue = Number(cca)+"CCA"
                }else{
                    this.ccaValue = Number(cca)+"A"
                }
                if(Number(stand)==0){
                    this.stdValue =  Number(std)+"CCA";
                }else if(Number(stand)==9){
                    this.stdValue =  Number(std)+"Ah";
                }else if(Number(stand)==8){
                    this.stdValue =  this.tjstd[Number(std)];
                }else{
                    this.stdValue = Number(std)+"A";
                }


            },
            async getData1() {
                await new Promise((resolve, reject) => {
                    axios.get('/tjdctype.json')
                        .then(result => {
                            this.tjdctype = result.data
                            resolve();
                        });
                })
            },
            async getData3() {
                await new Promise((resolve, reject) => {
                    axios.get('/tjstand.json')
                        .then(result => {
                            this.tjstand = result.data
                            resolve();
                        });
                })
            },
            async getData4() {
                await new Promise((resolve, reject) => {
                    axios.get('/tjstd.json')
                        .then(result => {
                            this.tjstd = result.data
                            resolve();
                        });
                })
            },
            async getData5() {
                await new Promise((resolve, reject) => {
                    axios.get('/tjyuyan2.json')
                        .then(result => {
                            this.tjyuyan = result.data
                            resolve();
                        });
                })
            },
            async readDict(){
                await this.getData1()
                await this.getData3()
                await this.getData4()
                await this.getData5()
                this.initData()
            }
        }
    };
</script>

<style scoped>
    .customPassword ::v-deep input {
        -webkit-text-security: disc;
    }
    .tjBox {
        background-color: rgba(66, 96, 230, 0);
        background-position: center;
        background-size: cover;
        padding: 10px 0px;
        color: #1411fa;
        font-weight: bold;
        /*height: 100px;*/
    }
    .fontBox{
        padding: 10px 15px;
    }
    .fontBox .timeBox{
        font-size: 14px;
    }
    .contentBox{
        font-weight: bold;
        padding: 10px 15px;
    }
    .contentBox .cardItemBox{
        padding-bottom: 20px;
    }
    .cardItemBox .cardTit{
        /*color: #0d0a06;*/
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
    }
    .titleBox{
        font-size: 18px;
        display: flex;
        justify-content: space-between;
    }
    .cardItemBox .cardContent{
        /*color: black;*/
        font-size: 18px;
    }
    .cardItemBox .cardDesc{
        color: #8c8e8d;
        font-size: 18px;
    }
    .separator {
        border: none; /* 移除默认边框 */
        border-top: 1px solid #202020; /* 加粗上边框，可以设置颜色 */
        margin: 2px 0; /* 设置上下的边距 */
    }
</style>
